import React from "react";

import { useAppSuccess } from "state/application/hooks";

export function useSuccessAlert() {
  //global state hook for app success
  const [appSuccess, setAppSuccess] = useAppSuccess();

  const setSuccess = (message: string) => {
    setAppSuccess({ message });
  };

  const resetSuccess = () => {
    setAppSuccess({ message: "" });
  };

  return { success: appSuccess, setSuccess, resetSuccess };
}
