import { createReducer } from "@reduxjs/toolkit";

import { setSubmissionId } from "./actions";

export interface SubmissionState {
  submissionId: number | null; //for resume modal & remark modal
}

const initialState: SubmissionState = {
  submissionId: null,
};

export default createReducer(initialState, (builder) =>
  builder.addCase(setSubmissionId, (state, action) => ({
    ...state,
    submissionId: action.payload,
  })),
);
