import React, { ReactNode, useCallback } from "react";
import * as auth from "auth-provider";
import { http } from "utils/http";
import { useMount } from "utils";
import { useAsync } from "hooks/useAsync";
import { User } from "shared/types/user";
import { FullPageErrorFallback, FullPageLoading } from "../components/lib";

interface AuthForm {
  email: string;
  password: string;
}

const bootstrapUser = async () => {
  let user = null;
  const token = auth.getToken();

  if (token) {
    const data = await http("admin/me", { token });
    user = data;
    user.token = token;

    window.localStorage.setItem("username", data?.userName);
  }

  return user;
};

const AuthContext = React.createContext<
  | {
      user: User | null;
      login: (form: AuthForm) => Promise<void>;
      logout: () => Promise<void>;
    }
  | undefined
>(undefined);

AuthContext.displayName = "AuthContext";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    data: user,
    error,
    isLoading,
    isIdle,
    isError,
    run,
    setData: setUser,
  } = useAsync<User | null>();

  const login = (form: AuthForm) => auth.login(form).then(setUser);

  const logout = () =>
    auth.logout().then(() => {
      setUser(null);
      window.localStorage.removeItem("username");
      window.localStorage.removeItem("token");
    });

  useMount(
    useCallback(() => {
      run(bootstrapUser());
    }, []),
  );

  if (isIdle || isLoading) {
    return <FullPageLoading />;
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />;
  }

  // eslint-disable-next-line react/no-children-prop
  return <AuthContext.Provider children={children} value={{ user, login, logout }} />;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must use in AuthProvider");
  }
  return context;
};
