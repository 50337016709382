// custom styles for overriding material-ui detail styles

const globalStyles = {
  "*": {
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },
  body: {
    margin: 0,
    fontFamily: `"-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu","Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"`,
    // "-webkit-font-smoothing": "antialiased",
    // "-moz-osx-font-smoothing": "grayscale",
    scrollBehavior: "smooth",
  },
  "#root": {
    background: "#f4f6fa",
    minHeight: "100vh",
    position: "relative",
  },

  // TextField
  ".MuiAutocomplete-root": {
    "& .MuiInputBase-root": {
      fontSize: 14,
      padding: "4px 9px !important",
    },
  },
  ".MuiFormControl-root, .MuiSelect-root, .MuiTextField-root": {
    "& label": {
      transform: "unset",
      transition: "unset",
      position: "relative",
      zIndex: 0,
      pointerEvent: "unset",
      fontSize: 14,
      marginBottom: "12px",
    },
    "& legend": {
      transform: "unset",
      transition: "unset",
      display: "none",
    },
    "& fieldset": {
      top: 0,
    },
    "> .MuiInputBase-root": {
      fontSize: 14,
    },
  },
  ".MuiOutlinedInput-input.MuiInputBase-input": {
    fontSize: 14,
    paddingTop: "12px",
    paddingBottom: "12px",

    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
};

export default globalStyles;
