import React from "react";
import { styled } from "@mui/styles";

import { Box } from "@mui/material";

import { TaskType } from "shared/types";

const CusChip = styled(Box)({
  borderRadius: 4,
  padding: "4px 8px",
  fontSize: 10,
  color: "#000",
  fontWeight: 500,
  marginRight: 6,
});

export interface TaskChipProps {
  taskType: TaskType;
}

export default function TaskChip({ taskType }: TaskChipProps) {
  const getChipInfo = (taskType: TaskType) => {
    const chipInfo = {
      label: "",
      color: "",
    };

    switch (taskType) {
      case "FRI":
      case "FRID":
        chipInfo.label = "FRI";
        chipInfo.color = "#AEC5E9";
        break;

      case "SPU":
      case "SPUD":
        chipInfo.label = "SPU";
        chipInfo.color = "#D5A910";
        break;

      case "QT":
        chipInfo.label = "QT";
        chipInfo.color = "#CEF6D5";
        break;

      case "PST":
        chipInfo.label = "PST";
        chipInfo.color = "#FFC7DB";
        break;

      case "SS":
        chipInfo.label = "SS";
        chipInfo.color = "#D8D8D8";
        break;

      case "ST":
      case "STD":
        chipInfo.label = "ST";
        chipInfo.color = "#FFD1C0";
        break;

      case "DPI":
        chipInfo.label = "DPI";
        chipInfo.color = "#FFD12E";
        break;

      case "PAC":
        chipInfo.label = "PAC";
        chipInfo.color = "#dfaee9";
        break;

      default:
        break;
    }

    return chipInfo;
  };

  const info = getChipInfo(taskType);

  return (
    <CusChip
      sx={{
        background: info?.color,
      }}
    >
      {info?.label}
    </CusChip>
  );
}
