import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import styled from "@emotion/styled";

const FullPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorBox = ({ error }: { error: unknown }) => {
  if (isError(error)) {
    return <Typography>{error?.message}</Typography>;
  }
  return null;
};

export const FullPageLoading = () => (
  <FullPage>
    <CircularProgress />
  </FullPage>
);

export const FullPageErrorFallback = ({ error }: { error: Error | null }) => (
  <FullPage>
    <ErrorBox error={error} />
  </FullPage>
);

const isError = (value: any): value is Error => value?.message;
