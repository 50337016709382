import React from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import Container from "@mui/material/Container";

export interface AppErrorAlertProps {
  className?: string;
  autoHideDuration?: SnackbarProps["autoHideDuration"];
  severity?: AlertProps["severity"];
  code?: number | null;
  message?: string;
  open: boolean;
  onClose: () => void;
}

const DEFAULT_DURATION = 10000;

export default function ErrorAppAlert({
  className = "",
  open = false,
  autoHideDuration = DEFAULT_DURATION,
  code = 0,
  message = "",
  severity = "error",
  onClose,
}: AppErrorAlertProps) {
  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      className={classnames(className, classes.snackbar)}
    >
      <Container>
        <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
          {code !== 0 && (
            <>
              <b>Status Code: </b> {code}
            </>
          )}{" "}
          &nbsp;
          {message && (
            <>
              <b>Message: </b> {message}
            </>
          )}{" "}
        </Alert>
      </Container>
    </Snackbar>
  );
}

const useStyles = makeStyles({
  snackbar: {
    width: "calc(100% - 48px)",
  },
});
