import Axios, { AxiosRequestConfig } from "axios";
import { configure } from "axios-hooks";

const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token: string | null = localStorage.getItem("token");

    if (token != null) {
      // config.headers.Authorization = `Bearer ${token}`;
      config.headers.Authorization = token;
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
});

// delay 1 second for response from api call
axios.interceptors.response.use((res) => {
  const TIMEOUT = 300;
  return new Promise((resolve) => setTimeout(() => resolve(res), TIMEOUT));
});

axios.interceptors.request.use(injectToken, (error) => Promise.reject(error));

export function axiosAuthorizationConfig(token: string) {
  axios.defaults.headers.common.Authorization = token;
}

export function axiosHooksInitializer(token?: string) {
  if (token) {
    axiosAuthorizationConfig(token);
  }

  configure({ axios });
}
