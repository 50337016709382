import qs from "qs";
import * as auth from "auth-provider";
import { useAuth } from "context/auth-context";
import { useCallback } from "react";

const apiUrl = process.env.REACT_APP_API_DOMAIN;

interface Config extends RequestInit {
  token?: string;
  data?: any;
}

export const http = async (
  endpoint: string,
  { data, token, headers, ...customConfig }: Config = {},
) => {
  let url = endpoint;

  const config = {
    method: "GET",
    headers: {
      Authorization: token ? `${token}` : "",
      "Content-Type": data ? "application/json" : "",
    },
    ...customConfig,
  };

  if (config.method.toUpperCase() === "GET") {
    url += `?${qs.stringify(data)}`;
  } else {
    config.body = JSON.stringify(data || {});
  }

  return window.fetch(`${apiUrl}/${url}`, config).then(async (response) => {
    if (response.status === 401 || response.status === 403) {
      await auth.logout();
      window.location.reload();
      return Promise.reject(new Error("please login again"));
    }

    const { data } = await response.json();

    if (response.ok) {
      return data;
    }
    return Promise.reject(data);
  });
};

export const useHttp = () => {
  const { user } = useAuth();

  return useCallback(
    (...[endpoint, config]: Parameters<typeof http>) =>
      http(endpoint, { ...config, token: user?.token }),
    [user?.token],
  );
};
