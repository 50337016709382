import React from "react";
import { styled } from "@mui/styles";

import { Tooltip, Grid, Box } from "@mui/material";

import { TenderingProgram } from "shared/types/tendering";

import { NOT_ACCPLICABLE } from "shared/constants";

import textOrUnknown from "utils/textOrUnknown";

const CusChip = styled("span")({
  background: "#003DA5",
  borderRadius: 2,
  padding: "2px 4px",
  fontSize: "10px !important",
  color: "#fff",
  fontWeight: 500,
  marginRight: 6,
  cursor: "pointer",
});

export interface TenderingChipProps {
  label?: string;
  detail?: {
    assignedLab: TenderingProgram["assignedLab"];
    exemptions: TenderingProgram["exemptions"];
  };
}

export default function TenderingChip({ label = "T", detail }: TenderingChipProps) {
  return detail?.assignedLab || !!detail?.exemptions?.length ? (
    <Tooltip
      arrow
      title={
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: "4px" }}>
            Lab: {textOrUnknown(detail?.assignedLab)}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ marginBottom: "4px" }}>
              Exception: {!detail?.exemptions?.length && NOT_ACCPLICABLE}
            </Box>

            {detail?.exemptions?.length
              ? detail?.exemptions?.map((exemption, index) => {
                  const isPreSafetyTest = exemption?.taskType === "PST";
                  const isSamplePickUp = exemption?.taskType === "SPU";
                  const isSafetyTest = exemption?.taskType === "ST";

                  return (
                    <React.Fragment key={index}>
                      {isPreSafetyTest && (
                        <Box>Pre-Safety Test - To {textOrUnknown(exemption?.labName)}</Box>
                      )}

                      {isSamplePickUp && (
                        <Box>Sample Pickup - To {textOrUnknown(exemption?.labName)}</Box>
                      )}

                      {isSafetyTest && (
                        <Box>Safety Test - To {textOrUnknown(exemption?.labName)}</Box>
                      )}
                    </React.Fragment>
                  );
                })
              : null}
          </Grid>
        </Grid>
      }
    >
      <CusChip>{label}</CusChip>
    </Tooltip>
  ) : (
    <CusChip>{label}</CusChip>
  );
}
