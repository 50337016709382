import { User } from "shared/types/user";

const apiUrl = process.env.REACT_APP_API_DOMAIN;

const localStorageKey = "token";

export const getToken = () => window.localStorage.getItem(localStorageKey);

export const handleUserResponse = (user: any) => {
  window.localStorage.setItem(localStorageKey, user.token || "");
  return user;
};

export const login = (data: { email: string; password: string }) =>
  fetch(`${apiUrl}/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (response.ok) {
      return handleUserResponse({
        email: data.email,
        token: response.headers.get("Authorization") || "",
      });
    }
    // return Promise.reject(await response.json());
    Promise.reject(await response.json());
  });

export const logout = async () => window.localStorage.removeItem(localStorageKey);
