import { createReducer } from "@reduxjs/toolkit";
import { SubmissionListData } from "shared/types/submission";

import { setListData, setRemarkData, setRefreshList, setSearchValue } from "./actions";

export interface SubmissionListState {
  list: SubmissionListData[];
  remark: string;
  isRefresh: boolean; // get latest list data
  searchValue: string;
}

const initialState: SubmissionListState = {
  list: [],
  remark: "",
  isRefresh: false,
  searchValue: "",
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setListData, (state, action) => ({
      ...state,
      list: action.payload,
      isRefresh: false,
    }))
    .addCase(setRemarkData, (state, action) => ({
      ...state,
      remark: action.payload,
    }))
    .addCase(setRefreshList, (state, action) => ({
      ...state,
      isRefresh: true,
    }))
    .addCase(setSearchValue, (state, action) => ({
      ...state,
      searchValue: action.payload,
    })),
);
