import { ChipProps } from "@mui/material/Chip";

import {
  Order,
  Currency,
  TaskStatusType,
  TaskTypeList,
  PbStatus,
  QuoteStatus,
  Category,
} from "shared/types";

export const NOT_ACCPLICABLE = "N/A";

export const SEASONAL = "seasonal";

export const EVERYDAY = "everyday";

export const BOOTS = "boots";

export const CATEGORY_OPTIONS = [
  {
    label: "Everyday",
    value: "everyday",
  },
  {
    label: "Seasonal",
    value: "seasonal",
  },
];

export const CATEGORY: {
  [key: string]: {
    label: string;
    value: Category;
  };
} = {
  everyday: {
    label: "Everyday",
    value: "everyday",
  },
  seasonal: {
    label: "Seasonal",
    value: "seasonal",
  },
};

export const CURRENCY_LIST: {
  label: string;
  value: Currency;
}[] = [
  {
    label: "HKD",
    value: "HKD",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "RMB",
    value: "RMB",
  },
];

export enum DateFormat {
  DATE_FORMAT_1 = "DD/MM/YYYY",
  DATE_FORMAT_2 = "YYYY-MM-DD",
  DATE_FORMAT_WITH_MONTH = "DD/MMM/YYYY",
  DATE_FORMAT_WITH_TIME = "DD/MM/YYYY hh:mm",
  DATE_FORMAT_WITH_MONTH_TIME = "DD/MMM/YYYY hh:mm",
}

export const INITIAL_PER_PAGE_OPTIONS = [10, 20, 50];
export const INITIAL_ROW_PER_PAGE = 10;
export const INITIAL_PAGINATION_STATE = {
  page: 0,
  rowsPerPage: INITIAL_ROW_PER_PAGE,
  rowsPerPageOptions: INITIAL_PER_PAGE_OPTIONS,
};

export const ORDER: { [key: string]: Order } = {
  ASC: "asc",
  DESC: "desc",
};

export const TASK_COLOR = {
  grey: "grey",
  blue: "blue",
  yellow: "yellow",
  green: "green",
  red: "red",
};

export const TASK_STATUS: TaskStatusType = {
  // GREY
  not_open_for_quote: {
    label: "Not Open For Quote",
    value: "not_open_for_quote",
    color: TASK_COLOR.grey,
  },
  closed: {
    label: "Closed",
    value: "closed",
    color: TASK_COLOR.grey,
  },
  rejected: {
    label: "Rejected",
    value: "rejected",
    color: TASK_COLOR.grey,
  },
  cancelled: {
    label: "Cancelled",
    value: "cancelled",
    color: TASK_COLOR.grey,
  },
  // BLUE
  waiting_for_lab_quote: {
    label: "Waiting For Lab Quote",
    value: "waiting_for_lab_quote",
    color: TASK_COLOR.blue,
  },
  quote_available: {
    label: "Quote Available",
    value: "quote_available",
    color: TASK_COLOR.blue,
  },
  quote_submitted: {
    label: "Quote Submitted",
    value: "quote_submitted",
    color: TASK_COLOR.blue,
  },
  quote_confirmed: {
    label: "Quote Confirmed",
    value: "quote_confirmed",
    color: TASK_COLOR.blue,
  },
  quote_confirmed_and_waiting_for_sample: {
    label: "Quote Confirmed And Waiting For Sample",
    value: "quote_confirmed_and_waiting_for_sample",
    color: TASK_COLOR.blue,
  },
  // YELLOW
  pb_updated: {
    label: "PB Updated",
    value: "pb_updated",
    color: TASK_COLOR.yellow,
  },
  sample_received: {
    label: "Sample Received",
    value: "sample_received",
    color: TASK_COLOR.yellow,
  },
  in_progress: {
    label: "In Progress",
    value: "in_progress",
    color: TASK_COLOR.yellow,
  },
  in_progress_revise_update: {
    label: "In Progress - Revise/Update",
    value: "in_progress_revise_update",
    color: TASK_COLOR.yellow,
  },
  pending: { label: "Pending", value: "pending", color: TASK_COLOR.yellow },
  retest_in_progress: {
    label: "Retest In Progress",
    value: "retest_in_progress",
    color: TASK_COLOR.yellow,
  },
  in_progress_result_report_updated: {
    label: "In Progress - Result/Report Updated",
    value: "in_progress_result_report_updated",
    color: TASK_COLOR.yellow,
  },
  in_progress_upload_pac_cert: {
    label: "In Progress - PAC Cert Updated",
    value: "in_progress_upload_pac_cert",
    color: TASK_COLOR.yellow,
  },
  arranging_sample: {
    label: "Arranging Sample",
    value: "arranging_sample",
    color: TASK_COLOR.yellow,
  },
  under_review: {
    label: "Under Review",
    value: "under_review",
    color: TASK_COLOR.yellow,
  },
  booking_reserved: {
    label: "Booking Reserved",
    value: "booking_reserved",
    color: TASK_COLOR.yellow,
  },
  waiting_for_wba_approval: {
    label: "Waiting For WBA Approval",
    value: "waiting_for_wba_approval",
    color: TASK_COLOR.yellow,
  },
  // GREEN
  accepted: {
    label: "Accepted",
    value: "accepted",
    color: TASK_COLOR.green,
  },
  completed: {
    label: "Completed",
    value: "completed",
    color: TASK_COLOR.green,
  },
  completed_accept: {
    label: "Completed-Accept",
    value: "completed_accept",
    color: TASK_COLOR.green,
  },
  completed_concession: {
    label: "Completed-concessed",
    value: "completed_concession",
    color: TASK_COLOR.green,
  },
  within_validity_period: {
    label: "Within Validity Period",
    value: "within_validity_period",
    color: TASK_COLOR.green,
  },
  // RED
  completed_reject: {
    label: "Completed-Reject",
    value: "completed_reject",
    color: TASK_COLOR.red,
  },
  fail: { label: "Fail Report", value: "fail", color: TASK_COLOR.red },
  pb_not_existed: {
    label: "PB Not Existed",
    value: "pb_not_existed",
    color: TASK_COLOR.red,
  },
  // UNKNOWN
  not_acknowledge: {
    label: "Acknowledge Project",
    value: "not_acknowledge",
    color: "",
  },
  // EMPTY TASK
  empty_task: {
    label: "No Active Task",
    value: "empty_task",
    color: "",
  },
};

export const PREPAYMENT_STATUS = {
  hasPrepayment: {
    label: "Is PI",
    value: true,
    color: "",
  },
  hasNotPrepayment: {
    label: "Has PI",
    value: false,
    color: "",
  },
  none: {
    label: NOT_ACCPLICABLE,
    value: null,
    color: "",
  },
};

export const TASK_TYPE: TaskTypeList = {
  FRI: {
    label: "Final Random Inspection",
    value: "FRI",
    taskFlow: "inspection",
  },
  FRID: {
    label: "Final Random Inspection",
    value: "FRID",
    taskFlow: "inspection",
  },
  SS: {
    label: "Sample Sealing",
    value: "SS",
    taskFlow: "inspection",
  },
  DPI: {
    label: "During Production Inspection",
    value: "DPI",
    taskFlow: "inspection",
  },
  SPU: {
    label: "Sample Pick Up",
    value: "SPU",
    taskFlow: "samplePickUp",
  },
  SPUD: {
    label: "Sample Pick Up",
    value: "SPUD",
    taskFlow: "samplePickUp",
  },
  QT: {
    label: "Quality Test",
    value: "QT",
    taskFlow: "testing",
  },
  PST: {
    label: "PST",
    value: "PST",
    taskFlow: "testing",
  },

  ST: {
    label: "Safety Test",
    value: "ST",
    taskFlow: "testing",
  },
  STD: {
    label: "STD",
    value: "STD",
    taskFlow: "testing",
  },

  PAC: {
    label: "PAC",
    value: "PAC",
    taskFlow: "testing",
  },
};

export const PB_STATUS: { [key: string]: PbStatus } = {
  NOT_EXISTED: "NOT_EXISTED",
  WIP: "WIP",
  SHIPPED: "SHIPPED",
  PARTIALLY_SHIPPED: "PARTIALLY_SHIPPED",
  INSPECTION_PASSED: "INSPECTION_PASSED",
};

export const INSPECTION_LEVEL: { [key: string]: string } = {
  I: "General Inspection Level I",
  II: "General Inspection Level II",
  III: "General Inspection Level III",
  S1: "General Inspection Level, S-1",
  S2: "General Inspection Level, S-2",
  S3: "General Inspection Level, S-3",
  S4: "General Inspection Level, S-4",
};

export const REPORT_RESULT_TYPE: { [key: string]: string } = {
  visitInVain: "Visit In Vain",
  pending: "Pending",
  pass: "Pass",
  reportAsIs: "Report As Is",
  fail: "fail",
};

export const QUOTE_STATUS: {
  [key: string]: {
    label: string;
    value: QuoteStatus;
  };
} = {
  accepted: { label: "Accepted", value: "accepted" },
  pending: { label: "Pending", value: "pending" },
  rejected: { label: "Rejected", value: "rejected" },
};

export const ATTACHMENT_TYPES = {
  productSpecification: {
    id: 1,
    label: "Product Specification",
    value: "product_specification",
  },
  artwork: { id: 2, label: "Artwork", value: "artworks" },
  otherDocument: {
    id: 3,
    label: "Other Document e.g. BOM / Circuit Diagram",
    value: "other_information",
  },
  aisUPCInformation: {
    id: 4,
    label: "AIS/Stibo - UPC Information",
    value: "ais_stibo_upc_information",
  },
  // aisFactoryInformation: {
  //   id: 5,
  //   label: 'AIS/Stibo - Factory Information',
  //   value: 'ais_stibo_factory_information',
  // },
  aisOuterInner: {
    id: 6,
    label: "AIS/Stibo - Outer/Inner",
    value: "ais_stibo_outer_inner",
  },
  supplierInhouseCartonDropTestReport: {
    id: 7,
    label: "Supplier Inhouse Carton Drop Test Report",
    value: "supplier_inhouse_carton_drop_test_report",
  },
};

export const TRF_DOCUMENT = {
  BOOKING_FORM_PLATFORM_USE_DOC: "WBA_SD001_WI_I_BOOKING_FORM_11_May_2020_FOR_PLATFORM_USE.doc",
  GENERAL_TESING_PLATFORM_USE_DOC: "WALG-GB-TRFS-GB-GENERAL_TESTING-V12_FOR_PLATFORM_USE.doc",
  SGS_WALGREENS_DOC: "SGS_Walgreens_TRF_Apr.2019.doc",
  GENERAL_INPECTION_REQUEST_FORM: "Generic Inspection Request Form Rev15 Protect.xlsm",
  PRODUCT_TESTING_DOWNLIST_DOC:
    "Walgreens_Product_Testing_Submittal_Form_for_ICW_without_pull_downlist.doc",
  ADMIN_BOOKING_FORM: "F-Admin-Booking_Form-WBA-210104-4.xlsx",
  SAMPLING_BOOKING_DOC: "WBA_Sampling_Booking.xls",
};

export const ACTIVE_STATUS: {
  [key: string]: {
    label: string;
    color: ChipProps["color"];
  };
} = {
  active: {
    label: "Active",
    color: "success",
  },
  inactive: {
    label: "Inactive",
    color: "error",
  },
};
