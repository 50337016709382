import React from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

export interface ResumeChipProps {
  className?: string;
  show?: boolean;
  label: string;
}

export default function ResumeChip({ className, show = false, label = "" }: ResumeChipProps) {
  const classes = useStyles();

  return show && label ? (
    <span className={classnames(classes.chip, className)}>{label}</span>
  ) : null;
}

const useStyles = makeStyles({
  chip: {
    background: "#FFD12E",
    border: "1px solid #D5A910",
    borderRadius: 2,
    padding: 2,
    fontSize: 10,
    color: "#433501",
    fontWeight: 500,
    marginLeft: 5,
  },
});
