import { createReducer } from "@reduxjs/toolkit";
// import { UserListData } from "shared/types/user";
import { SupplierListData } from "shared/types/supplier";

import { setListData, setRefreshList, setUserId } from "./actions";

export interface SupplierState {
  list: SupplierListData[];
  isRefresh: boolean; // get latest list data
  userId: number | string | null; // get supplier id
}

const initialState: SupplierState = {
  list: [],
  isRefresh: false,
  userId: null,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setListData, (state, action) => ({
      ...state,
      list: action.payload,
      isRefresh: false,
    }))
    .addCase(setRefreshList, (state, action) => ({
      ...state,
      isRefresh: true,
    }))
    .addCase(setUserId, (state, action) => ({
      ...state,
      userId: action.payload,
    })),
);
