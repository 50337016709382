import React from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

export interface Program777ChipProps {
  className?: string;
  show?: boolean;
}

export default function Program777Chip({ className, show = false }: Program777ChipProps) {
  const classes = useStyles();

  return show ? <span className={classnames(classes.chip, className)}>777</span> : null;
}

const useStyles = makeStyles({
  chip: {
    backgroundColor: "#668BC9",
    color: "#fff",
    borderRadius: "4px",
    height: "24px",
    width: "24px",
    padding: "4px 8px",
    fontWeight: 400,
    fontSize: "12px",
  },
});
