import { createReducer } from "@reduxjs/toolkit";
import { UserListData } from "shared/types/user";
import { setListData } from "./actions";

export interface BuyerState {
  list: UserListData[];
}

const initialState: BuyerState = {
  list: [],
};

export default createReducer(initialState, (builder) =>
  builder.addCase(setListData, (state, action) => ({
    ...state,
    list: action.payload,
  })),
);
