import { configureStore } from "@reduxjs/toolkit";

import supplier from "pages/User/Supplier/state/reducer";
import lab from "pages/User/Lab/state/reducer";
import buyer from "pages/User/Buyer/state/reducer";
import submission from "pages/Submission/state/reducer";
import submissionList from "pages/Submission/SubmissionList/state/reducer";
import invoiceList from "pages/Invoice/InvoiceList/state/reducer";
import application from "./application/reducer";

const store = configureStore({
  reducer: {
    //* insert reducer here *//
    application,
    supplier,
    lab,
    buyer,
    submission,
    submissionList,
    invoiceList,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
  devTools: process.env.NODE_ENV === "development",
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
