import { createAction } from "@reduxjs/toolkit";
// import { UserListData } from "shared/types/user";
import { SupplierListData } from "shared/types/supplier";

const setListData = createAction<SupplierListData[]>("supplier/setListData");

const setRefreshList = createAction("supplier/setRefreshList");

const setUserId = createAction<number | string | null>("supplier/setUserId");

export { setListData, setRefreshList, setUserId };
