import { createReducer } from "@reduxjs/toolkit";

import { InvoiceListData } from "shared/types/invoice";

import { setListData, setSearchValue } from "./actions";

export interface InvoiceListState {
  list: InvoiceListData[];
  searchValue: string;
}

const initialState: InvoiceListState = {
  list: [],
  searchValue: "",
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setListData, (state, action) => ({
      ...state,
      list: action.payload,
    }))
    .addCase(setSearchValue, (state, action) => ({
      ...state,
      searchValue: action.payload,
    })),
);
