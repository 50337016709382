import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ErrorBoundary } from "react-error-boundary";

import { GlobalStyles, GlobalStylesProps } from "@mui/material";
import { useAppError } from "state/application/hooks";

import { ErrorAppAlert, SuccessAppAlert } from "components/atoms";
import ErrorFallback from "components/atoms/ErrorFallback";

import { useSuccessAlert } from "hooks/useAlert";

import { axiosHooksInitializer } from "api/config";
import { useAuth } from "./context/auth-context";
import { FullPageLoading } from "./components/lib";
import globalStyles from "./globalStyles";

const Login = React.lazy(() => import("pages/Login"));
const Routes = React.lazy(() => import("Routes"));

const theme = createTheme();

function App() {
  axiosHooksInitializer();

  const { user } = useAuth();

  const [appError, setAppError] = useAppError();

  const { success, resetSuccess } = useSuccessAlert();

  const onCloseAppError = () => {
    const initErrorState = { code: 0, message: "" };
    setAppError(initErrorState);
  };

  const hasAppError = Boolean(appError.code || appError.message);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={theme}>
          <GlobalStyles styles={globalStyles as GlobalStylesProps["styles"]} />
          <CssBaseline />

          <ErrorAppAlert
            open={hasAppError}
            code={appError?.code}
            message={appError?.message}
            onClose={onCloseAppError}
          />

          <SuccessAppAlert
            message={success?.message}
            open={!!success?.message}
            onClose={resetSuccess}
          />

          <React.Suspense fallback={<FullPageLoading />}>
            {user ? <Routes /> : <Login />}
          </React.Suspense>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
