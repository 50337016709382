import { createAction } from "@reduxjs/toolkit";
import { SubmissionListData } from "shared/types/submission";
import { SubmissionListState } from "./reducer";

const setListData = createAction<SubmissionListData[]>("submissionList/setListData");

const setRemarkData = createAction<SubmissionListState["remark"]>("submissionList/setRemarkData");

const setRefreshList = createAction("submissionList/setRefreshList");

const setSearchValue = createAction<string>("submissionList/setSearchValue");

export { setListData, setRemarkData, setRefreshList, setSearchValue };
