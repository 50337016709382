import React from "react";
import { styled } from "@mui/styles";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import Container from "@mui/material/Container";

const CusSnackbar = styled(Snackbar)({
  width: "calc(100% - 48px)",
});

export interface AppSuccessAlertProps {
  sx?: SnackbarProps["sx"];
  autoHideDuration?: SnackbarProps["autoHideDuration"];
  severity?: AlertProps["severity"];
  message?: string;
  open: boolean;
  onClose: () => void;
}

const DEFAULT_DURATION = 10000;

export default function SuccessAppAlert({
  sx,
  open = false,
  autoHideDuration = DEFAULT_DURATION,
  message = "",
  severity = "success",
  onClose,
}: AppSuccessAlertProps) {
  return (
    <CusSnackbar sx={sx} open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Container>
        <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Container>
    </CusSnackbar>
  );
}
