import { createReducer } from "@reduxjs/toolkit";
import { AppModal } from "shared/types";
import { setError, setSuccess, setModal } from "./actions";

export interface ApplicationState {
  error: {
    code?: number | null | undefined;
    message: string;
  };
  success: {
    message: string;
  };
  modal: AppModal | null;
}

const initialState: ApplicationState = {
  error: {
    code: 0,
    message: "",
  },
  success: {
    message: "",
  },
  modal: null,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setError, (state, action) => ({
      ...state,
      error: {
        code: action.payload.code || 0,
        message: action.payload.message || "",
      },
    }))
    .addCase(setSuccess, (state, action) => ({
      ...state,
      success: {
        message: action.payload.message || "",
      },
    }))
    .addCase(setModal, (state, action) => ({
      ...state,
      modal: action.payload,
    })),
);
